<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Admin Information</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm"
                        iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div style="position: relative" v-if="!showloaderpage">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label for="clientname">
                                    Admin Name
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="adminname" class="p-text-capitalize" v-model.trim="clientname"
                                    required="true" maxlength="20" autofocus
                                    :class="{ 'p-invalid': submitted && !clientname }" />
                                <small class="p-invalid p-error" v-if="v$.clientname.$error">{{
                                    v$.clientname.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="clientshortname">
                                    Admin Short Name
                                    <!-- <span class="p-invalid p-error">*</span> -->
                                </label>
                                <InputText id="clientshortname" class="p-text-capitalize" v-model.trim="clientshortname"
                                    required="true" maxlength="20" autofocus
                                    :class="{ 'p-invalid': submitted && !clientshortname }" />
                                <!-- <small class="p-invalid p-error" v-if="v$.clientshortname.$error">{{
                                    v$.clientshortname.$errors[0].$message
                                }}</small> -->
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="officemobileno">Office Contact Number <span
                                        class="p-invalid p-error">*</span></label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">
                                        +91
                                    </span>
                                    <InputText id="officemobileno" v-model="officemobileno" autocomplete="off"
                                        maxlength="10" @keypress="onlyNumber" autofocus
                                        :class="{ 'p-invalid': submitted && !officemobileno }" />
                                </div>

                                <small class="p-invalid p-error" v-if="v$.officemobileno.$error">{{
                                    v$.officemobileno.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="officeemail">Office email ID <span
                                        class="p-invalid p-error">*</span></label>
                                <InputText id="email" v-model="officeemail" autocomplete="off" maxlength="50" autofocus
                                    :class="{ 'p-invalid': submitted && !officeemail }" />
                                <small class="p-invalid p-error" v-if="v$.officeemail.$error">{{
                                    v$.officeemail.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="state">
                                    State
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Dropdown id="state" v-model="state" :options="stateList" optionLabel="label"
                                    placeholder="Select ..." appendTo="body" @change="onStateSelect">
                                </Dropdown>
                                <small class="p-invalid p-error" v-if="v$.state.$error">{{ v$.state.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="city">
                                    City
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <!-- <MultiSelect id="city" v-model="city" :options="cityList" optionLabel="label"
                                    placeholder="Select Cities" appendTo="body" mode="tags"></MultiSelect> -->

                                <AutoComplete class="p-fluid" id="city" v-model="city" multiple
                                    :suggestions="citySuggestions" appendTo="body" @complete="searchCities"
                                    placeholder="Select ..." field="label" dropdown />
                                <small class="p-invalid p-error" v-if="v$.city.$error">{{ v$.city.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="officeaddress">
                                    Office Address
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Textarea id="officeaddress" class="p-text-capitalize" v-model="officeaddress"
                                    maxlength="250" :autoResize="true" rows="1" cols="30" required="true" autofocus
                                    :class="{ 'p-invalid': submitted && !officeaddress }" />
                                <small class="p-invalid p-error" v-if="v$.officeaddress.$error">{{
                                    v$.officeaddress.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label>Generate Invoice? <span class="p-invalid p-error"> *</span></label>
                                <div class="p-field-radiobutton">
                                    <RadioButton id="invoice_yes" name="option" v-bind:value="1"
                                        v-model="invoicegenerator" :checked="invoicegenerator == 1" />
                                    <label for="invoice_yes" class="p-mr-2">Yes</label>
                                    <RadioButton id="invoice_no" name="option" v-bind:value="0"
                                        v-model="invoicegenerator" :checked="invoicegenerator == 0" />
                                    <label for="invoice_no" class="p-mr-2">No</label>
                                </div>
                                <small class="p-invalid p-error" v-if="v$.invoicegenerator.$error">{{
                                    v$.invoicegenerator.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="clientstatus">
                                    Admin Status
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Dropdown id="clientstatus" v-model="clientstatus" :options="clientstatusList"
                                    optionLabel="label" placeholder="Select ..." appendTo="body"></Dropdown>
                                <small class="p-invalid p-error" v-if="v$.clientstatus.$error">{{
                                    v$.clientstatus.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col-12 p-md-12">
                                        <label for="image">
                                            Admin Logo
                                            <!-- <span class="p-invalid p-error">*</span> -->
                                        </label>
                                        <FileUpload mode="basic" ref="clientlogo" :maxFileSize="1000000"
                                            accept="image/*" chooseLabel="Upload Logo" auto
                                            @select="handleClientLogoUpload" />
                                        <small class="p-invalid p-error" v-if="clientlogoError">{{ clientlogoerror
                                            }}</small>
                                        <!-- <small class="p-invalid p-error" v-if="v$.clientlogo.$error">{{
                                            v$.clientlogo.$errors[0].$message
                                        }}</small> -->
                                        <div>
                                            <img v-if="clientlogoUrl && clientlogoUrl.url" class="p-pt-2"
                                                role="presentation" :alt="clientlogoUrl.name" :src="clientlogoUrl.url"
                                                width="120" height="120" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="guests_validation">Guests Validation (In Days)
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <div class="p-inputgroup">
                                    <InputText id="guests_validation" v-model="guests_validation" autocomplete="off"
                                        maxlength="3" @keypress="onlyNumber" autofocus
                                        :class="{ 'p-invalid': submitted && !guests_validation }" />
                                </div>
                                <small class="p-invalid p-error" v-if="v$.guests_validation.$error">{{
                                    v$.guests_validation.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-4"> 
                                <label for="system_activation_date">System Activation Date <span
                                        class="p-invalid p-error"> *</span></label>
                                <Calendar :showIcon="true" :monthNavigator="true" :yearNavigator="true"
                                    v-model="system_activation_date" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                                    appendTo="body" :manualInput="false"></Calendar>
                                <small class="p-invalid p-error" v-if="v$.system_activation_date.$error">{{
                                    v$.system_activation_date.$errors[0].$message }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="p-d-flex p-pt-4">
                        <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                            @click="updateAdminInfo" style="width: 90px"></Button>
                        <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color"
                            class="p-button-text p-ml-auto" style="width: 90px"></Button>
                    </div>
                </div>
                <div v-if="showloaderpage" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- image preview dialog start here -->
    <Dialog v-model:visible="ImagePreviewDialog" :maximizable="true" :style="{ width: '400px' }" header="Preview"
        :modal="true" class="p-fluid whatsapp-preview-outer">
        <div class="p-fluid p-formgrid p-grid">
            <img :src="previewimg" class="modal-preview-image" />
        </div>
    </Dialog>
    <!-- image preview dialog start here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import router from '@/router';
import { required, helpers, email, numeric, maxLength, minLength } from '@vuelidate/validators';
import moment from 'moment';

export default {
    data() {
        return {
            v$: useValidate(),
            clientname: '',
            clientshortname: '',
            clientlogo: '',
            clientlogoError: '',
            clientlogoUrl: '',
            officeaddress: '',
            state: '',
            stateList: [],
            city: '',
            cityList: [],
            officemobileno: '',
            officeemail: '',
            invoicegenerator: '',
            clientstatus: '',
            clientstatusList: [
                { label: 'Active', value: 0 },
                { label: 'Soft Suspension', value: 1 },
                { label: 'Temporary Suspension', value: 2 },
                { label: 'Suspended', value: 3 },
                { label: 'Deletion Notice Period', value: 4 },
                { label: 'Permanent Suspended', value: 5 },
                { label: 'Deleted', value: 6 },
                { label: 'Under Maintenance', value: 7 },
            ],
            clientInfo: [],
            submitted: false,
            showLoader: false,
            ImagePreviewDialog: false,
            maa6: '',
            showloaderpage: false,
            citySuggestions: [],
            storage_path: '',
            routeParam: '',
            guests_validation: '',
            system_activation_date: null,
        };
    },
    validations() {
        return {
            clientname: { required: helpers.withMessage('Please enter candidate name', required) },
            //clientshortname: { required: helpers.withMessage('Please enter client short name', required) },
            officeaddress: { required: helpers.withMessage('Please enter office address', required) },
            state: { required: helpers.withMessage('Please select state', required) },
            city: { required: helpers.withMessage('Please select city', required) },
            officeemail: { required: helpers.withMessage('Please enter email address', required), email: helpers.withMessage('Email address is invalid', email) },
            officemobileno: { required: helpers.withMessage('Please enter mobile number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(10) },
            invoicegenerator: { required: helpers.withMessage('Please select option', required) },
            clientstatus: { required: helpers.withMessage('Please select client status', required) },
            guests_validation: { required: helpers.withMessage('Please enter guests validation (In Days)', required) },
            system_activation_date: { required: helpers.withMessage('Please select date', required) },

        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.adminSubId;
        this.getStates();
        this.getAdminUserDetails({ maa1: this.routeParam });
        this.storage_path = JSON.parse(localStorage.masterBucketName);
    },
    methods: {
        onStateSelect(e) {
            this.selectclientcity(e.value);
        },
        getStates(ev) {
            this.ApiService.getStates(ev).then((data) => {
                if (data.status == 200) {
                    this.stateList = data.data;
                } else {
                    this.stateList = '';
                }
            });
        },
        selectclientcity(ev) {
            this.getCity(ev);
        },
        getCity(e) {
            if (e.value) {
                this.ApiService.getCities({ state_id: e.value }).then((items) => {
                    if (items.status === 200) {
                        this.cityList = items.data;
                    }
                });
            }
        },
        searchCities(e) {
            this.citySuggestions = this.cityList.flatMap(d => d.label.toLowerCase().includes(e.query) ? [{ label: d.label, value: d.value }] : []);
        },
        getAdminUserDetails(item) {
            this.showloaderpage = true;
            this.ApiService.getAdminUserDetails(item).then((data) => {
                if (data.status == 200) {
                    this.clientInfo = data.data.records;
                    this.clientid = this.clientInfo.maa1;
                    this.clientname = this.clientInfo.maa2;
                    this.clientshortname = this.clientInfo.maa3;
                    this.officemobileno = this.clientInfo.maa17;
                    this.officeemail = this.clientInfo.maa18;
                    if (this.clientInfo.maa9) {
                        this.state = { value: this.clientInfo.maa8, label: this.clientInfo.maa9 };
                        this.getCity({ value: this.state.value })
                    }
                    if (this.clientInfo.maa12) {
                        this.city = this.selectedValues({ label: this.clientInfo.maa12, value: this.clientInfo.maa11 });
                    }

                    this.officeaddress = this.clientInfo.maa7;
                    this.invoicegenerator = this.clientInfo.maa20;
                    if (this.clientInfo.maa37 == 0) {
                        this.clientstatus = { value: 0, label: 'Active' };
                    }
                    if (this.clientInfo.maa37 == 1) {
                        this.clientstatus = { value: 1, label: 'Soft Suspension' };
                    }
                    if (this.clientInfo.maa37 == 2) {
                        this.clientstatus = { value: 2, label: 'Temporary Suspension' };
                    }
                    if (this.clientInfo.maa37 == 3) {
                        this.clientstatus = { value: 3, label: 'Suspended' };
                    }
                    if (this.clientInfo.maa37 == 4) {
                        this.clientstatus = { value: 4, label: 'Deletion Notice Period' };
                    }
                    if (this.clientInfo.maa37 == 5) {
                        this.clientstatus = { value: 5, label: 'Permanent Suspended' };
                    }
                    if (this.clientInfo.maa37 == 6) {
                        this.clientstatus = { value: 6, label: 'Deleted' };
                    }
                    if (this.clientInfo.maa37 == 7) {
                        this.clientstatus = { value: 7, label: 'Under Maintenance' };
                    }
                    this.clientlogoUrl = { name: this.clientInfo.maa6, url: this.clientInfo.maa6 && `https://storage.googleapis.com/${this.storage_path}/AdminUser/Logo/${this.clientInfo.maa6}` };
                    if (data.aa11 != null) {
                        this.userprofilefile = data.aa11;
                    }

                    if (this.clientInfo.maa4 != null) {
                        this.system_activation_date = moment(this.clientInfo.maa4).format("DD/MM/YYYY");
                    }

                    this.guests_validation = this.clientInfo.maa49;
                    this.showloaderpage = false;
                }
                this.showloaderpage = false;
            });
        },
        selectedValues({ label, value }) {
            value = value.split(',');
            label = label.split(',')
            return value.map((d, i) => { return { label: label[i], value: d } })
        },
        updateAdminInfo() {
            this.submitted = true;
            this.v$.$validate();
            var formData = new FormData();
            let selectedCitiesValues = this.city.map(d => d.value).join();
            let selectedCitiesLabels = this.city.map(d => d.label).join();
            formData.append('maa1', this.clientid);
            formData.append('maa2', this.clientname);
            formData.append('maa3', this.clientshortname);
            formData.append('maa8', this.state.value);
            formData.append('maa9', this.state.label);
            formData.append('maa11', selectedCitiesValues);
            formData.append('maa12', selectedCitiesLabels);

            if (this.clientlogo != null && this.clientlogo != '') {
                formData.append('maa6', this.clientlogo ? this.clientlogo : this.clientlogoUrl && this.clientlogoUrl.name ? this.clientlogoUrl.name : '');
            }
            formData.append('maa7', this.officeaddress);
           
            formData.append('maa4',  moment(this.system_activation_date).format('YYYY-MM-DD') );
            formData.append('maa17', this.officemobileno);
            formData.append('maa18', this.officeemail);
            formData.append('maa20', this.invoicegenerator);
            formData.append('maa37', this.clientstatus.value);
            formData.append('maa24', this.clientInfo.maa24);
            formData.append('maa25', this.clientInfo.maa25);
            formData.append('maa49', this.guests_validation);

            for (var pair of formData.entries()) {
                console.log(pair[0] + ' - ' + pair[1]);
            }

            if (!this.v$.$error) {

                this.showLoader = true;
                this.ApiService.createUpdateAdminUser(formData).then((items) => {
                    if (items.status == 200) {
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.addUserDialogStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.goToDashboard();
                        }, 3000);
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        handleClientLogoUpload() {
            var clientlogo = this.$refs.clientlogo.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!clientlogo) {
                //this.clientlogoerror = 'Please upload logo';
            } else if (!clientlogo.size > 2048) {
                this.clientlogoerror = 'File size cannot exceed more than 2MB';
            } else if (!clientlogo.name.match(allowedExtensions)) {
                this.clientlogoerror = 'Invalid file type';
            } else {
                this.clientlogo = this.$refs.clientlogo.files[0];
                this.clientlogoUrl = { name: this.clientlogo.name, url: this.clientlogo.objectURL };
                this.clientlogoerror = '';
            }
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (!this.officemobileno && !+$event.key) {
                $event.preventDefault();
            }

            if ((keyCode < 48 || keyCode > 57) && keyCode === 46) {
                $event.preventDefault();
            }
        },
        // clientLogoPreview(maa6) {
        //     this.previewimg = maa6;
        //     this.ImagePreviewDialog = true;
        // },
        goToDashboard() {
            router.push({
                name: "admindashboard",
                params: { adminId: this.routeParam },

            });
        },
        goToNext() {
            router.push({
                name: "adminconfig",
                params: { adminSubId: this.routeParam },

            });
        },
     
    },
};
</script>

<style scoped></style>
